import React from 'react'
import './About.css'
import { Language, English, HochDeutsche, Hebrew } from './text'
import Pic from '../../assets/face3.png'
import { FaAward, FaCode } from 'react-icons/fa'
import { MdOutlineArticle } from 'react-icons/md'

const About = () => {
	return (
		<section className="aboutSection" id='about' >
			<h2>{Language.title}</h2>
			<div className='container about__container'>
				<div className='about__me'>
					<div className='about__me_image'>
						<img src={Pic} alt={Language.altpic} />
					</div>
				</div>

				<div className='about__content'>
					<div className='about__cards'>
						<a href='#experience' className='about__card'>
							<article >
								<FaAward className='about__icon' />
								<h5> {Language.experience} </h5>
								<small>{Language.workExperience}</small>

							</article>
						</a>

						<a href='#projects' className='about__card'>
							<article >
								<MdOutlineArticle className='about__icon' />
								<h5> {Language.projects} </h5>
								<small>{Language.projectsComment}</small>

							</article>
						</a>

						<a href='#skills' className='about__card'>
							<article >
								<FaCode className='about__icon' />
								<h5> {Language.codes} </h5>
								<small>{Language.codesComment}</small>

							</article>
						</a>
					</div>

					<p>
						{Language.bio}
					</p>

					<a href="#contact" className='btn btn-primary buttonPosition'>{Language.contact}</a>
				</div>

			</div>
		</section>
	)
}

export default About