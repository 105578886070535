import React from 'react'
import Header from './components/header/header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/Experience/Experience'
import Education from './components/Education/education'
import Skills from './components/skills/Skills'
import Projects from './components/projects/Projects'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Volunteering from './components/volunteering/Volunteering'
import './index.css'

const App = () => {
    return (
        <>
            <Header />
            <Nav />
            <About />
            <Education />
            <Experience />
            <Volunteering />
            <Skills />
            <Projects />
            <Contact />
            <Footer />
        </>
    )
}

export default App