import React, { useRef } from 'react'
import emailjs from 'emailjs-com'
import './Projects.css'
import '../Education/education.css'
import { Language } from './text'
import waterTanks from '../../assets/Projects/WaterTanks.pdf'
import bushfire from '../../assets/Bushfire.jpg'
import harrisFarmProject from '../../assets/Projects/Changing the Demand for Regenerative Agriculture.pdf'
import harrisFarmLogo from '../../assets/HarrisFarm.png'
import CaronCapture from '../../assets/Projects/CarbonCapture.pdf'
import foodwasteImage from '../../assets/foodcapture.JPG'
import PipeScanningPaper from '../../assets/Projects/DvelopmentplanPipes.pdf'
import pipe from '../../assets/pipeScanner.JPG'
import feasibility from '../../assets/Projects/feasibilityStudy.pdf'
import flood from '../../assets/lismoreflood.jpg'
import thesis from '../../assets/Projects/Quantitative_Analysis_of_Weed_Databases__Assessing_Their_Potential_for_Training_Crop_Weed_Detection_Systems (2).pdf'
import weed from '../../assets/weed.jpg'

const Projects = () => {

	return (
		< section className="projectsSection" id='projects'>
			<h2> Projects</h2>
			<div className='EducationContainer'>

				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={bushfire} alt="" className='experienceImage' />
						<a href={waterTanks} className='btn btn-primary'>{Language.IntegratedEngineeringButton}</a>
					</div>
					<div className='TextContainer'>
						<p>{Language.BushfireText}</p>
					</div>

				</div>

				<div className='EducationExperienceLeft'>

					<div className='TextContainer'>
						<p>{Language.RegenText}</p>
					</div>
					<div className='Image_container'>
						<img src={harrisFarmLogo} alt="" className='experienceImage' />
						<a href={harrisFarmProject} className='btn btn-primary'>{Language.HarrisButton}</a>
					</div>


				</div>

				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={pipe} alt="" className='experienceImage' />
						<a href={PipeScanningPaper} className='btn btn-primary'>{Language.pipeButton}</a>
					</div>
					<div className='TextContainer'>
						<p>{Language.PipeText}</p>
					</div>


				</div>

				<div className='EducationExperienceLeft'>

					<div className='TextContainer'>
						<p>{Language.CarbonText}</p>
					</div>
					<div className='Image_container'>
						<img src={foodwasteImage} alt="" className='experienceImage' />
						<a href={CaronCapture} className='btn btn-primary'>{Language.carbonButton}</a>
					</div>


				</div>
				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={flood} alt="" className='experienceImage' />
						<a href={feasibility} className='btn btn-primary'>{Language.feasibilityButton}</a>
					</div>
					<div className='TextContainer'>
						<p>{Language.JFCText}</p>
					</div>
				</div>
				<div className='EducationExperienceLeft'>

					<div className='TextContainer'>
						<p>{Language.ThesisText}</p>
					</div>
					<div className='Image_container'>
						<img src={weed} alt="" className='experienceImage' />
						<a href={thesis} className='btn btn-primary'>{Language.thesisButton}</a>
					</div>


				</div>


			</div>
		</section>
	)
}

export default Projects