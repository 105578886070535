import React from 'react'
import '../Education/education.css'
import './Volunteering.css'
import { English, Language } from './text'
import JFCImage from '../../assets/JacarandaConsultantsLogo_V2_5.jpg'
import Reflection from '../../assets/Work Experience Reflection.pdf'
import TutoringImage from '../../assets/tutoring.jpg'
import stuartBlinds from '../../assets/stuartblinds.png'
import onStage from '../../assets/onstage.jpg'
import dematic from '../../assets/Dematic_logo.svg.png'
import friendshipCircle from '../../assets/sydneyfc.JPG'
import SES from '../../assets/SES.png'
import CatholicCare from '../../assets/care.png'


const Volunteering = () => {
	return (
		<section className="VolunteeringSection" id='experience'>
			<h2>{Language.title}</h2>

			<div className='EducationContainer'>

				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={friendshipCircle} alt="" className='experienceImage' />
					</div>
					<div className='TextContainer'>
						<p>{Language.JFCText}</p>
					</div>

				</div>

				<div className='EducationExperienceLeft'>

					<div className='TextContainer'>
						<p>{Language.OnStageText}</p>
					</div>
					<div className='Image_container'>
						<img src={SES} alt="" className='educationImage' />
					</div>


				</div>

				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={CatholicCare} alt="" className='educationImage' />
					</div>
					<div className='TextContainer'>
						<p>{Language.StuText}</p>
					</div>


				</div>


			</div>
		</section>
	)
}

export default Volunteering