

export const English = {
    title: 'About me',
    altpic: 'Image of Aaron Sandelowsky',
    experience: "Experience",
    workExperience: "1+ Years of Internships",
    codes: 'Software',
    codesComment: 'Learnt 14 languages',
    projects: 'Projects',
    projectsComment: '5 papers',
    bio: ' I am Aaron Sandelowsky I am a graduate Engineering Student at the University of Sydney. ',
    contact: 'Let\'s Talk',
}
export const HochDeutsche = {
    title: 'Mechatroniker',
    Resume: 'Lebenslauf',


    contact: 'Erreichen',

}

export const Hebrew = {
    title: 'מהנדס מכטרוני',
    contact: 'להושיט יד'

}

export const Language = English;