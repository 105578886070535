

export const English = {
    Introduction: 'Hello I\'m',
    Name: 'Aaron Sandelowsky',
    title: 'Mechatronic Engineer',
    Resume: 'Resume',
    contact: 'Let\'s Talk',
    scroll: 'Scroll Down'
}
export const HochDeutsche = {
    Introduction: 'Guten Tag, Ich Bin',
    Name: 'Aaron Sandelowsky',
    title: 'Mechatroniker',
    Resume: 'Lebenslauf',
    contact: 'Erreichen',
    scroll: 'Runterscrollen'
}

export const Hebrew = {
    Introduction: 'שלוס, השם שלי',
    Name: 'אהרן סנדלוסקי',
    title: 'מהנדס מכטרוני',
    Resume: 'קורות חיים',
    contact: 'להושיט יד',
    scroll: 'גלול מטה'
}

export const Language = English;