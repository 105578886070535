

export const English = {
    title: 'Skills',
    
}
export const HochDeutsche = {

}

export const Hebrew = {

}

export const Language = English;