import React from 'react'
import './Skills.css'
import { Hebrew, English, Language, HochDeutsche } from './text'
import HTMLCertificate from '../../assets/Certificates/HTML.pdf'
import CSSCert from '../../assets/Certificates/CertificateOfCompletion_CSS Essential Training 2019.pdf'
import JSCert from '../../assets/Certificates/CertificateOfCompletion_JavaScript Essential Training.pdf'
import advancedC from '../../assets/Certificates/CertificateOfCompletion_Advanced C Programming Optimize Performance and Efficiency.pdf'
import HVAC1 from '../../assets/Certificates/IntroductiontoHVAC.pdf'
import HVAC2 from '../../assets/Certificates/HVACAllAir.pdf'
import HVAC3 from '../../assets/Certificates/HVACallwater.pdf'
import solidity from '../../assets/Certificates/CertificateOfCompletion_Blockchain Learning Solidity.pdf'
import AlternativePower from '../../assets/Certificates/Alternative Power Generation.pdf'
import wind from '../../assets/wind.pdf'
import PHP from '../../assets/Certificates/PHP.pdf'
import rust from '../../assets/Certificates/Rust.pdf'

const Skills = () => {
	return (
		<section id='skills' className="ion">
			<h2>{Language.title}</h2>
			<div className='skillsListColumns'>
				<div className='skillsList'>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={HTMLCertificate} download >
							HTML Certificate
						</a>
						<div className='SkillName'>
							HTML
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={CSSCert} download >
							CSS Certificate
						</a>
						<div className='SkillName'>
							CSS
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={JSCert} download >
							JS Certificate
						</a>
						<div className='SkillName'>
							JavaScript
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={advancedC} download >
							C Certificate
						</a>
						<div className='SkillName'>
							C
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={solidity} download >
							Solidity Certificate
						</a>
						<div className='SkillName'>
							Solidity
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={rust} download >
							RUST Certificate
						</a>
						<div className='SkillName'>
							RUST
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={PHP} download >
							PHP Certificate
						</a>
						<div className='SkillName'>
							PHP
						</div>
					</div>
				</div>
				<div className='skillsList'>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={HVAC1} download >
							HVAC Certificate 1
						</a>
						<div className='SkillName'>
							Introduction to HVAC
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={HVAC2} download >
							HVAC Certificate 2
						</a>
						<div className='SkillName'>
							HVAC Systems II: All-Air Systems and Temperature Control
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={HVAC3} download >
							HVAC Certificate 3
						</a>
						<div className='SkillName'>
							HVAC Systems III: Air-and-Water and All-Water Systems
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={AlternativePower} download >
							Power Certificate
						</a>
						<div className='SkillName'>
							Alternative Power Technologies
						</div>
					</div>
					<div className='skill'>
						<a alt="" className='skillProof btn-primary' href={wind} download >
							Wind Certificate
						</a>
						<div className='SkillName'>
							Wind Energy and Turbine Power Generation
						</div>
					</div>
				</div>
			</div>
			<div className='SkillsUniList'>
				<h3> <strong>Skills Developed during University: </strong></h3>
				<p> Assembly Coding Language, Computer aided Design (Solidworks, Fusion 360), Field Programmable Gate Arrays (FPGAs),  Data Science (R), Object Orientated Programming (C++, Python), Computer Vison (OpenCV, Matlab), Microcontrollers ( Arduino, ESP32), Machine Learning (Pytorch and Keras) </p>
			</div>
		</section>
	)
}

export default Skills