import React, { useRef } from 'react'
import emailjs from 'emailjs-com'
import './Contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { BsWhatsapp } from 'react-icons/bs'

const Contact = () => {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
		emailjs.sendForm('service_cia4ic5', 'template_k5m6eum', form.current, 'gL4o5kDXnzuDp4vzh')
		e.target.reset()
	};

	return (
		< section id='contact'>
			<h5> Get In Touch</h5>
			<h2> Contact Me </h2>

			<div className='container contact__container'>
				<div className="contact__options">
					<article className='contact__option'>
						<MdOutlineEmail className='contact-icon' />
						<h4>Email</h4>
						<h5>asandelowsky@gmail.com</h5>
						<a href="mailto:asandelowsky@gmail.com" target="_blank"> Email Me</a>
					</article>
					<article className="contact__option">
						<RiMessengerLine className='contact-icon' />
						<h4>Messenger</h4>
						<h5><i>AaronSandelowsky</i></h5>
						<a href="https://m.me/aaronSandelowsky" target="_blank"> Message Me</a>
					</article>
					<article className="contact__option">
						<BsWhatsapp className='contact-icon' />
						<h4>Whatsapp</h4>
						<h5>+61 451 009 701</h5>
						<a href=" https://wa.me/61451009701" target="_blank"> Text Me</a>
					</article>
				</div>
				<form ref={form} onSubmit={sendEmail}>
					<input type="text" name='name' placeholder='Your Full Name' required />
					<input type="email" name='email' placeholder='Your Email' required />
					<textarea name="message" rows="7" placeholder="Your Message ..." required></textarea>
					<button type='submit' className='btn btn-primary'>Send Message</button>
				</form>
			</div>
		</section>
	)
}

export default Contact