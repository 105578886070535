

export const English = {
    title: 'Volunteering',
    JFCText: 'Since 2021, I have been actively engaged with the Sydney Friendship Circle group, dedicated to providing care and support for teenagers and adults living with disabilities. My involvement has encompassed a wide range of activities, including weekend events and getaways. This experience has been truly transformative, as it has allowed me to provide full-time assistance and create meaningful connections with the individuals we serve.',
    OnStageText: 'During my time with the NSW SES, my responsibilities extended to both community outreach and disaster relief efforts. Notably, during the 2019 storms, I played a vital role in clearing roads and roofs of fallen trees, as well as in the installation of tarps on rooftops to prevent water from infiltrating homes through cracks. Furthermore, at community fairs, I actively participated in educating the public about the importance of maintaining clear gutters and practicing safe behavior in flood-prone areas, thus fostering greater community resilience and preparedness.',
    StuText: 'During my tenure with Catholic Care, I volunteered at Dom\'s House in Waitara, where I provided support to homeless adults. This experience exposed me to the multifaceted challenges associated with homelessness and addiction, as well as the various services and resources available in Australia to address these issues. My role primarily involved tasks such as cleaning, restocking supplies, tending to the garden, managing compost, and engaging in meaningful communication with the residents, all of which contributed to creating a more supportive and caring environment.',
    TutoringText: 'Since completing high school, I have worked as an academic tutor, assisting students ranging from year 6 to HSC level. This role has enabled me to impart my own experiences and strategies, equipping current students with the best possible preparation for their future endeavors. Central to my approach is a reflection-based methodology, wherein I administer exams and tests that empower students to recognize and rectify their technical errors and recurring mistakes independently.',
    DematicText: 'In 2017, I engaged in a week of work experience at Dematic, Belrose, Sydney.This exposure unveiled advanced robotics like welding and self- driving forklifts, highlighting automation\'s potential. The experience deepened my understanding of software, hardware, and control engineering, provided by a major global logistics automation company. This encounter ignited my passion for engineering and underscored its practical impact.',
    TranscriptButton: 'Reflection'
}
export const HochDeutsche = {

}

export const Hebrew = {

}

export const Language = English;