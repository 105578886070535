import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import './header.css'
const HeaderSocials = () => {
    return (
        <div className='header_socials'>
            <a href="https://www.linkedin.com/in/aaron-sandelowsky-25432316a/"><BsLinkedin /></a>
            <a href="https://github.com/AaronSandelowsky"><FaGithub /></a>
        </div>
    )
}

export default HeaderSocials
