import React from 'react'
import './header.css'
import { English, HochDeutsche, Hebrew, Language } from './text'
import CTA from './CTA'
import picture from '../../assets/profile.png'
import HeaderSocials from './headerSocials'


const Header = () => {
	// let language = Hebrew;
	return (
		<header>
			<div className="container header__container">
				<h5>{Language.Introduction}</h5>
				<h1>{Language.Name}</h1>
				<h5 className='text-light'>{Language.title}</h5>
				<CTA />
				<HeaderSocials />
				<div className='me'>
					<img className="profilePic" src={picture} alt={Language.name} />
				</div>

				<a href="#contact" className='scroll_down'>{Language.scroll}</a>
			</div>

		</header>
	)
}

export default Header