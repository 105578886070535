

export const English = {
    title: 'Education',
    UniText: 'From March 2020 to November 2024, I pursued a degree in Mechatronic Engineering at the University of Sydney. This multidisciplinary field integrates Mechanical, Electrical, Software, and Control Engineering, providing me with a comprehensive education in automation, robotics, and mechatronic systems. I also served as the Executive Director of the Robotics Club and also took on the responsibility of IT Director at COMM-STEM.',
    SchoolText: 'I attended Masada College High School from 2014 to 2019, excelling in subjects like Physics, Chemistry, Extension Maths, Economics, and English. I held leadership roles as Prefect, Peer-Support, and SRC leader while actively participating in extracurricular activities such as Debating, Mock Trial, cricket, surfing, and the Da Vinci Decathlon.',
    TafeText: 'In 2021, I successfully achieved a Statement of Attainment in Welding from NSW TAFE. This intensive 7-week program provided comprehensive training in various welding techniques, including MIG, TIG, and Stick Welding, applied to both steel and aluminum materials. The course encompassed essential skills such as Oxy cutting, emphasizing safety protocols and the proper use of personal protective equipment',
    TranscriptButton: 'Academic Transcript'
}
export const HochDeutsche = {

}

export const Hebrew = {

}

export const Language = English;