import React from 'react'
import '../Education/education.css'
import './Experience.css'
import { English, Language } from './text'
import JFCImage from '../../assets/JacarandaConsultantsLogo_V2_5.jpg'
import Reflection from '../../assets/Work Experience Reflection.pdf'
import TutoringImage from '../../assets/tutoring.jpg'
import stuartBlinds from '../../assets/stuartblinds.png'
import onStage from '../../assets/onstage.jpg'
import dematic from '../../assets/Dematic_logo.svg.png'

const Experience = () => {
	return (
		<section className="longSection" id='experience'>
			<h2>{Language.title}</h2>

			<div className='EducationContainer'>

				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={JFCImage} alt="" className='experienceImage' />
						<a href={Reflection} className='btn btn-primary'>{Language.TranscriptButton}</a>
					</div>
					<div className='TextContainer'>
						<p>{Language.JFCText}</p>
					</div>

				</div>

				<div className='EducationExperienceLeft'>

					<div className='TextContainer'>
						<p>{Language.OnStageText}</p>
					</div>
					<div className='Image_container'>
						<img src={onStage} alt="" className='educationImage' />
					</div>


				</div>

				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={stuartBlinds} alt="" className='educationImage' />
					</div>
					<div className='TextContainer'>
						<p>{Language.StuText}</p>
					</div>


				</div>

				<div className='EducationExperienceLeft'>

					<div className='TextContainer'>
						<p>{Language.TutoringText}</p>
					</div>
					<div className='Image_container'>
						<img src={TutoringImage} alt="" className='educationImage' />
					</div>


				</div>
				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={dematic} alt="" className='educationImage' />
					</div>
					<div className='TextContainer'>
						<p>{Language.DematicText}</p>
					</div>


				</div>


			</div>
		</section>
	)
}

export default Experience