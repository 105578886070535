import React from 'react'
import './Footer.css'
import { FaFacebook } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { FaLinkedin } from 'react-icons/fa'

const Footer = () => {
	return (
		<footer>
			<a href="#" className='footer__logo'>Aaron Sandelowsky</a>

			<ul className='permalinks'>
				<li><a href="#">Home</a></li>
				<li><a href="#about">About</a></li>
				<li><a href="#education">Education</a></li>
				<li><a href="#expereince">Home</a></li>
				<li><a href="#skills">Home</a></li>
				<li><a href="#project">`Home</a></li>
				<li><a href="#contact">Contact</a></li>
			</ul>

			<div className='footer__socials'>
				<a href="https://www.facebook.com/aaronSandelowsky"><FaFacebook /></a>
				<a href="https://www.linkedin.com/in/aaronsandelowsky-25432316a/"> <FaLinkedin /></a>
				<a href="https://www.instagram.com/aaronsandelowsky/"><FiInstagram /></a>
			</div>

			<div className='footerCopyright'>
				<small>&copy; Aaron Sandelowsky Web Designs. All rights reserved.</small>
			</div>
		</footer>
	)
}

export default Footer