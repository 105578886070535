import React from 'react'
import { Hebrew, English, HochDeutsche, Language } from './text'
import CV from '../../assets/Aaron Sandelowsky Resume 24_07_2023.pdf'
import './header.css'
const CTA = () => {
    return (
        <div className='cta'>
            <a href={CV} download className='btn'>{Language.Resume}</a>
            <a href="#contact" className='btn btn-primary'>{Language.contact}</a>
        </div>
    )
}

export default CTA
