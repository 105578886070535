

export const English = {
    title: 'Work Experience',
    BushfireText: 'In my initial university semester post the black summer bushfires, our assignment was to devise a solution for addressing the upcoming bushfire season in Australia. Our team honed in on water availability during fires, aiming to establish a consistent supply for Rural Fire Services and farmers amid droughts. We conceptualized a mass-produced steel water tank facilitating a national water market. This system would allow water collection across Australia, enabling sales to farmers or the government in crises. The design promotes an open and transparent market, detailed further in the accompanying document.',
    RegenText: 'During my June 2022 Holiday unit, I collaborated with Harris Farm Markets on an emissions reduction project. Our team aimed to mitigate corporate risk by encouraging customers to opt for sustainable and regenerative foods. Our strategy involved a two-phase technological approach. Initially, we utilized TikTok to promote regenerative meats and environmentally conscious choices appealing to younger, ethically-minded consumers. Subsequently, the Harris Farm app was introduced, allowing customers to follow "Rege-cipies," monitor spending, track carbon emissions, and assess the carbon footprint of preferred food items. The accompanying report delves into a comprehensive research analysis and design process.',
    PipeText: 'In another university project, we extended our focus on sustainability from the bushfire projects to enhance Sydney University\'s ecological footprint.Specifically, we targeted water reliability on campus.Initially, we devised a UV and eddy current sensor system to map the intricate drainage networks of the historic Great Hall and Maclaurin Hall, each over a century old.This technology detects cracks, generating 3D models for AI analysis to gauge pipe health and lifespan, optimizing maintenance strategies.Recommendations included employing shade balls and water tanks to minimize water evaporation and implementing a greywater recycling system aligned with Sydney University\'s 2030 goals.Additionally, rainwater harvesting and purification systems were designed for comprehensive water management.',
    CarbonText: 'My Research Report delves into the multifaceted impacts of global warming on society, encompassing social, ethical, and economic dimensions. As temperatures escalate in the air and oceans, future consequences intensify. To combat this, I propose a solution for Australia to achieve carbon negativity. Utilizing Regenerative Agriculture and Reforestation, we can extract and safely store carbon dioxide underground. This environmentally conscious approach aims to curb global temperature increases, averting the anticipated catastrophic 8-degree rise by 2080. Explore my findings on addressing climate change and fostering sustainability for a resilient future.',
    JFCText: 'Collaborating with Jacaranda Flame Consulting, we proposed an innovative solution to the former NSW government. Instead of discarding retired train carriages into landfills, we advocated repurposing them to address the escalating issue of homelessness in Sydney, Australia, and globally. Citing ABS data indicating a 14% increase in homelessness since 2016, our proposal addresses the disproportionate impact on Indigenous Australians, who make up 16% of those affected. We emphasized the link between natural disasters, like bushfires and floods, and homelessness, citing the displacement and long-term consequences. Additionally, we highlighted the housing crisis in rural areas, urging government intervention to prevent economic decline and social challenges.',
    ThesisText: 'This honors thesis centers on analyzing various labeled image databases containing global and Australian weeds. The objective is to identify characteristics that yield the most accurate models through YOLOv5 network training. Surprisingly, the results reveal that extensive datasets do not consistently result in the highest accuracy in training or validation metrics. Notably, controlled design aspects, such as simulations of dry conditions and strategically planted weeds, consistently contribute to elevated metrics for recall, precision, and F1 scores',
    IntegratedEngineeringButton: 'Bushfire Relief Plan',
    HarrisButton: 'Recommendations Report',
    pipeButton: 'Development Plan',
    carbonButton: 'Personal Research Project',
    feasibilityButton: 'Feasibility Study',
    thesisButton: 'Honors Thesis Paper'
}
export const HochDeutsche = {

}

export const Hebrew = {

}

export const Language = English;