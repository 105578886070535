

export const English = {
    title: 'Work Experience',
    JFCText: 'Between December 2022 and February 2023, I engaged in a transformative internship at Jacaranda Flame Consulting. During this period, I focused on creating a feasibility study aimed at repurposing decommissioned trains and buses into shelters for urban homeless individuals and those displaced by natural calamities like the Lismore floods and Black Summer bushfires. Collaborating within a diverse team of Engineers, I also liaised with experts in social security and housing policy. Our comprehensive efforts culminated in a presentation to former Transport Minister David Elliot, where we showcased our innovative designs, budget projections, and deployment strategies.',
    OnStageText: 'In my current role at OnStage Presentations, I am actively engaged in the creation of accessibility software tailored for individuals with hearing impairments. This software facilitates the real-time conversion of announcements and auditory content into text, which is then promptly displayed on BrightSign Player screens. The project involves leveraging existing APIs and conducting front-end development to ensure seamless integration on the client\'s side.',
    StuText: 'From January 2021 to June 2002, I worked as a Receptionist at Stuart Blinds in Manly. In this position, I actively participated in sales, repairs, and orders of blinds, awnings, and curtains. Being surrounded by individuals with decades of experience in the industry was truly inspiring, and the unrestricted access to their workshop greatly complemented my engineering studies. Engaging in on-site calls for blind repairs enriched both my technical proficiency and interpersonal aptitude.',
    TutoringText: 'Since completing high school, I have worked as an academic tutor, assisting students ranging from year 6 to HSC level. This role has enabled me to impart my own experiences and strategies, equipping current students with the best possible preparation for their future endeavors. Central to my approach is a reflection-based methodology, wherein I administer exams and tests that empower students to recognize and rectify their technical errors and recurring mistakes independently.',
    DematicText: 'In 2017, I engaged in a week of work experience at Dematic, Belrose, Sydney.This exposure unveiled advanced robotics like welding and self- driving forklifts, highlighting automation\'s potential. The experience deepened my understanding of software, hardware, and control engineering, provided by a major global logistics automation company. This encounter ignited my passion for engineering and underscored its practical impact.',
    TranscriptButton: 'JFC Reflection'
}
export const HochDeutsche = {

}

export const Hebrew = {

}

export const Language = English;