import React from 'react'
import './education.css'
import { English, HochDeutsche, Hebrew, Language } from './text'

import masadaImage from '../../assets/masada.png'
import TAFEImage from '../../assets/tafe.jpg'
import USYDImage from '../../assets/USydLogo-1.jpg'
import transcript from '../../assets/Academic Transcript.pdf'
const Education = () => {
	return (
		<section className='EducationSection' id='education'>
			<h2>{Language.title}</h2>

			<div className='EducationContainer'>

				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={USYDImage} alt="" className='educationImage' />
						<a href={transcript} className='btn btn-primary'>{Language.TranscriptButton}</a>
					</div>
					<div className='TextContainer'>
						<p>{Language.UniText}</p>
					</div>

				</div>

				<div className='EducationExperienceLeft'>

					<div className='TextContainer'>
						<p>{Language.SchoolText}</p>
					</div>
					<div className='Image_container'>
						<img src={masadaImage} alt="" className='educationImage' />
					</div>


				</div>

				<div className='EducationExperience'>
					<div className='Image_container'>
						<img src={TAFEImage} alt="" className='educationImage' />
					</div>
					<div className='TextContainer'>
						<p>{Language.TafeText}</p>
					</div>


				</div>


			</div>
		</section>
	)
}

export default Education